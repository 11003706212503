import GeneralStructure from "../components/general/GeneralStructure";
import {useThemeContext} from "../store/StoreVariables";
import {useQuery} from "react-query";

// React
import * as React from "react";
import {useEffect, useState} from "react";

// Config
import config from "../components/Config";

// APIs
import DailyApiPathGenerator from "../components/utils/api_requests/DailyApiPathGenerator";
import ReferenceApiPathGenerator from "../components/utils/api_requests/ReferenceApiPathGenerator";
import {AggApiPathGenerator} from "../components/utils/api_requests/AggApiPathGenerator";
import LastTimeStampPathGenerator from "../components/utils/api_requests/LastTimeStampPathGenerator";

// Mui
import {Grid} from "@mui/material";
import TransparentPaper from "../components/general/TransparentPaper";
import {PageTitle, PlotTitle} from "../components/general/Titles";
import LastUpdate from "../components/general/LastUpdate";

// Functions
import {TurnZoomRelative2Position, TurnZoomRelative2PositionEnd} from "../components/utils/TurnZoomRelative2Position";
import {FormatCollectionString} from "../components/utils/FormatCollectionString";
import ChargingData from "../components/plots/ChargingData";
import getCurrentDate from "../components/utils/CurrentDate";
import {setYear} from "../components/utils/SetYear";
import IsFirstDateEquivalent from "../components/utils/DataQuality";
import isSameYear from "../components/utils/IsSameYear";

// Plots
import PrecZoomPlot from "../components/plots/Precipitation/PrecZoomPlot";
import PrecipitationRankingTable from "../components/precipitation/PrecipitationRankingTable";
import {PrecGauge} from "../components/plots/Gauge/PrecGauge";
import PrecMainPlot from "../components/plots/Precipitation/PrecMainPlot";
import PrecEvolutionMetrics from "../components/precipitation/PrecEvolutionMetrics";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Helmet} from "react-helmet-async";
import {UserFirstInteractionGA} from "../components/ReactGaComponents";
import SpecialTime from "../components/precipitation/SpecialTime";

export default function Precipitation() {
  const {
    mode, stationName, zoomStartDaily, setZoomStartDaily, zoomEndDaily, setZoomEndDaily,
    minDateSelected, setMinDateSelected, maxDateSelected, setMaxDateSelected,
    selectedYearInTable, setSelectedYearInTable, selectedYear, setSelectedYear,
    orderTempBy, setOrderTempBy, setPageSelected, pageSelected
  } = useThemeContext()

  // Mouse click state:
  const [mouseUnClick, setMouseUnClick] = useState(true)

  // Plot
  const [zoomPlot, setzoomPlot] = useState(null);
  const [precPlot, setPrecPlot] = useState(null);
  const [refLegendFlag, setRefLegendFlag] = useState(true);
  const [actLegendFlag, setActLegendFlag] = useState(true);
  const [dailyLegendFlag, setDailyLegendFlag] = useState(true);

  // UI Size
  const smDown = useMediaQuery("(max-width:600px)");
  const mdDown = useMediaQuery("(max-width:900px)");

  // Dates:
  const [yearRanking, setYearRanking] = React.useState(false);
  const currentDate = getCurrentDate() // Daily and Reference Queries always refer Current Date - 1 year

  const dailyQuery = useQuery({
    queryKey: ["dailyPrec", stationName, selectedYear],
    staleTime: Infinity,
    queryFn: () => {
      const getRequest = DailyApiPathGenerator({
        query: "get_data_daily",
        database: "daily",
        collection: FormatCollectionString(stationName),
        maxDate: setYear(currentDate, selectedYear),
        fields: "date,prec"
      })
      if (config.devMode) {
        console.log("GET Daily: " + getRequest)
      }
      return fetch(getRequest).then((response) => response.json())
    },
    onError: () => {
      // Reset state values to their default values
      setZoomStartDaily(75);
      setZoomEndDaily(100);
      setMinDateSelected(false);
      setMaxDateSelected(getCurrentDate());
      setSelectedYearInTable(getCurrentDate().split("-")[0]);
      setSelectedYear(getCurrentDate().split("-")[0]);
      setOrderTempBy("prec_sum");
    }
  });

  const referenceQuery = useQuery({
    queryKey: ["referencePrec", stationName],
    staleTime: Infinity,
    queryFn: () => {
      const getRequest = ReferenceApiPathGenerator({
        query: "get_reference_data",
        collection: FormatCollectionString(stationName),
        fields: "avg_prec",
        maxDate: currentDate
      })
      if (config.devMode) {
        console.log("GET Reference: " + getRequest)
      }
      return fetch(getRequest).then((response) => response.json())
    }
  });

  const customYearlyQuery = useQuery({
    queryKey: ['customYearlyPrec', stationName, minDateSelected, maxDateSelected],
    staleTime: Infinity,
    enabled: !!minDateSelected && !!maxDateSelected && !!mouseUnClick,
    queryFn: () => {
      const getRequest = AggApiPathGenerator({
        basePath: "get_yearly_agg_period",
        collection: FormatCollectionString(stationName),
        naturalYear: false,
        minDate: minDateSelected,
        maxDate: maxDateSelected,
        fields: "prec",
        aggTypes: "sum"
      })
      if (config.devMode) {
        console.log("GET Yearly Custom Data: " + getRequest)
      }
      return fetch(getRequest).then((response) => response.json())
    }
  });

  const lastTimeStamp = useQuery(
    {
      queryKey: ["lastTimeStamp", stationName],
      staleTime: Infinity,
      queryFn: () => {
        const getRequest = LastTimeStampPathGenerator({
            stationName: FormatCollectionString(stationName)
          }
        )
        if (config.devMode) {
          console.log("GET Last Date: " + getRequest)
        }
        return fetch(getRequest).then((response) => response.json())
      }
    }
  );

  useEffect(() => {
    // Initializes MinDateSelected and MaxDateSelected after retrieving dailyQuery.data
    // Max date will be the last date available, while min date will be the date 75% of the data
    if (dailyQuery.isSuccess && !minDateSelected) {
      const minDatePosition = TurnZoomRelative2PositionEnd(75, dailyQuery.data.length)
      setMinDateSelected(dailyQuery.data[minDatePosition - 1].date)
      setMaxDateSelected(dailyQuery.data[dailyQuery.data.length - 1].date)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyQuery.isSuccess]) // Only the first render


  // zoomPlot useEffect
  useEffect(() => {
    if (dailyQuery.isSuccess && referenceQuery.isSuccess) {
      const getCandleData = (zoomStart, zoomEnd) => {
        setZoomStartDaily(zoomStart);
        setZoomEndDaily(zoomEnd);
        const [startPosition, endPosition] = TurnZoomRelative2Position(
          zoomStart,
          zoomEnd,
          dailyQuery.data.length
        )

        setMinDateSelected(dailyQuery.data[startPosition].date)
        setMaxDateSelected(dailyQuery.data[endPosition - 1].date)

        // Define Selected year as year in maxDateSelected
        setSelectedYearInTable(dailyQuery.data[endPosition - 1].date.split("-")[0])
      };

      // DQ checks:
      IsFirstDateEquivalent(referenceQuery.data, dailyQuery.data)

      setzoomPlot(PrecZoomPlot({
        onChange: getCandleData,
        dates: dailyQuery.data.map(obj => obj.date),
        zoomStartDaily: zoomStartDaily,
        zoomEndDaily: zoomEndDaily,
        smDown: smDown,
        mdDown: mdDown
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyQuery.isSuccess, referenceQuery.isSuccess, mode, stationName, smDown, mdDown])

  // PrecMainPlot useEffect
  useEffect(() => {
    if (dailyQuery.isSuccess && referenceQuery.isSuccess) {
      setPrecPlot(PrecMainPlot({
        mode: mode,
        dates: dailyQuery.data.map(obj => obj["date"]),
        actualData: dailyQuery.data.map(obj => obj.prec),
        refData: referenceQuery.data.map(obj => obj.avg_prec),
        zoomStartDaily: zoomStartDaily,
        zoomEndDaily: zoomEndDaily,
        refLegendFlag: refLegendFlag,
        actLegendFlag: actLegendFlag,
        dailyLegendFlag: dailyLegendFlag,
        setRefLegendFlag: setRefLegendFlag,
        setActLegendFlag: setActLegendFlag,
        setDailyLegendFlag: setDailyLegendFlag,
        smDown: smDown,
        mdDown: mdDown
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyQuery.isSuccess, referenceQuery.isSuccess, mode, stationName, smDown, mdDown,
    zoomStartDaily, zoomEndDaily, refLegendFlag, actLegendFlag, dailyLegendFlag])

  return (
    <GeneralStructure
      setPageSelected={setPageSelected}
      pageSelected={pageSelected}
    >
      <Helmet>
        <title>ClimaObserver | Precipitaciones</title>
        <meta name="description"
              content="Consulta las precipitaciones en España en tiempo real. Precipitacion acumulada y referente histórico"/>
        <link rel="canonical" href="https://climaobserver.com"/>
      </Helmet>

      <Grid item xs={12} id="GeneralMetricsGrid">
        <TransparentPaper sx={{
          p: 0,
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 'none',
          borderRadius: '0',
          height: 'auto'
        }}>
          <PageTitle>
            Ranking Anual (Periodo seleccionado)
          </PageTitle>
          <Grid container spacing={0} id="TablePlusGauge" sx={{
            alignItems: "center"
          }
          }>
            {/* Table */}
            <Grid item xs={12} md={8} lg={8.5} id="Table" sx={{minHeight: 368}}>
              {dailyQuery.isLoading && <ChargingData/>}
              {dailyQuery.isSuccess && customYearlyQuery.isSuccess && referenceQuery.isSuccess &&
                < PrecipitationRankingTable
                  selectedYear={selectedYearInTable}
                  selectedYearInTableAux={selectedYear}
                  isSameYear={isSameYear(minDateSelected, maxDateSelected)}
                  setSelectedYear={setSelectedYear}
                  setSelectedYearInTable={setSelectedYearInTable}
                  data={customYearlyQuery.data}
                  stationName={stationName}
                  mode={mode}
                  orderBy={orderTempBy}
                  setOrderBy={setOrderTempBy}
                  setYearRanking={setYearRanking}
                />}
            </Grid>

            {/* Gauge */}
            {!mdDown && (
              <Grid item xs={12} md={4} lg={3.5} id="Gauge" sx={{minHeight: 300}}>
                {dailyQuery.isLoading && customYearlyQuery.isLoading && referenceQuery.isLoading &&
                  <ChargingData/>}
                {dailyQuery.isSuccess && customYearlyQuery.isSuccess && referenceQuery.isSuccess &&
                  <PrecGauge
                    mode={mode}
                    ranking={yearRanking}
                    rankingLenght={customYearlyQuery.data.filter(obj => obj["prec_sum"] !== null).length}
                  />}
              </Grid>
            )}
          </Grid>
        </TransparentPaper>
        <TransparentPaper
          sx={{
            p: 0,
            display: 'flex',
            flexDirection: 'column',
            boxShadow: 'none',
            borderRadius: '0',
            mt: 1,
          }}
        >
          {/* Daily reference comparison */}
          <Grid container spacing={0} sx={{alignItems: "center"}}>

            {/* Chart */}
            <Grid item xs={12} md={9} lg={10}>
              <PlotTitle> Precipitación actual vs Referente histórico </PlotTitle>
              <TransparentPaper sx={{
                height: 360,
                pb: 2,
              }}
                                onClick={UserFirstInteractionGA({action: "Plot Click", label: "Prec Plot"})}
              >
                {dailyQuery.isLoading && <ChargingData/>}
                {dailyQuery.isError && (
                  <div>{`El periodo seleccionado no esta disponible para esta estación`}</div>)}
                {dailyQuery.isSuccess && referenceQuery.isSuccess && precPlot}
                <div
                  // Div Mouse or Touch control
                  onMouseDown={() => setMouseUnClick(false)}
                  onMouseUp={() => setMouseUnClick(true)}
                  onTouchStart={() => setMouseUnClick(false)}
                  onTouchEnd={() => setMouseUnClick(true)}
                >
                  {dailyQuery.isSuccess && referenceQuery.isSuccess && zoomPlot}
                </div>
              </TransparentPaper>
            </Grid>

            {/* LastUpdate - Only small screens */}
            <Grid
              item xs={12} md={0} lg={0}
              display={{xs: 'block', md: 'none', lg: 'none'}}
              sx={{mb: 1.5}}
            >
              {lastTimeStamp.isSuccess &&
                <LastUpdate
                  date={lastTimeStamp.data.date}
                  hour={lastTimeStamp.data.hour}
                  smdown={smDown}
                />}
              <br/>
              <SpecialTime/>
            </Grid>

            {/* Metrics */}
            <Grid item xs={12} md={3} lg={2} sx={{p: 0.05}}>
              <TransparentPaper sx={{
                p: 0,
                boxShadow: 'none',
                borderRadius: '0',
              }}>
                {dailyQuery.isSuccess && referenceQuery.isSuccess &&
                  <PrecEvolutionMetrics
                    reference={referenceQuery.data.map(obj => obj.avg_prec)}
                    actuals={dailyQuery.data.map(obj => obj.prec)}
                    zoomStart={zoomStartDaily}
                    zoomEnd={zoomEndDaily}
                  />}
              </TransparentPaper>
            </Grid>

            {/* LastUpdate - Only big screens */}
            <Grid
              item xs={0} md={12} lg={12}
              display={{xs: 'none', md: 'block', lg: 'block'}}
            >
              {lastTimeStamp.isSuccess &&
                <LastUpdate
                  date={lastTimeStamp.data.date}
                  hour={lastTimeStamp.data.hour}
                  smdown={smDown}
                />}
              <br/>
              <SpecialTime/>
            </Grid>
          </Grid>
        </TransparentPaper>
      </Grid>
    </GeneralStructure>
  );
}
