import {Typography} from "@mui/material";

export default function SpecialTime() {
    return (
        <Typography
            variant="caption"
            color="textSecondary"
            align="left"
            sx={{
                pl: 1,
            }}
        >
            ** Días definidos desde las 7:00 a las 7:00
        </Typography>
    );
}
